import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogProps, IconButton } from '@mui/material';
import React from 'react';
import { ConditionalWrapper } from './ConditionalWrapper';

interface VyModalProps extends Omit<Partial<DialogProps>, 'maxWidth' | 'title'> {
  children: (onClose: () => void, onSubmit?: () => void) => JSX.Element;
  onClose?: () => void;
  onSubmit?: () => any;
  title?: React.ReactNode;
  open?: boolean;
  displayCloseButton?: boolean;
  hasDefaultPadding?: boolean; // whether the modal has a padding by default
  maxWidth?: string | number;
  disableDefaultStyling?: boolean;
  disableEnforceFocus?: boolean;
  maxHeight?: string;
  useEventDimensions?: boolean;
  style?: React.CSSProperties;
  backgroundColor?: string;
  headerAction?: React.ReactNode;
}

export default function VyModal({
  children,
  onClose = () => {},
  onSubmit,
  title = '',
  displayCloseButton = false,
  open = false,
  hasDefaultPadding = true,
  maxWidth = '550px',
  disableEnforceFocus = false,
  maxHeight = '80vh',
  useEventDimensions = false,
  style = {},
  backgroundColor = 'white',
  headerAction,
  ...restOfProps
}: VyModalProps) {
  if (!open) return null;

  return (
    <>
      <Dialog
        style={{ borderRadius: 0, width: '100cqw' }}
        disableEnforceFocus={disableEnforceFocus}
        maxWidth={false}
        open={true}
        onClose={() => onClose?.()}
        {...restOfProps}>
        {/* this input needs to be here. */}
        <input className="hidden" />
        <div
          className={`${hasDefaultPadding ? 'p-4 sm:p-8' : ''}`}
          style={{
            ...(useEventDimensions ? { width: '60cqw', maxWidth: '60rem', minHeight: '35rem' } : {}),
            ...style,
          }}>
          <ConditionalWrapper
            condition={!!onSubmit}
            wrapper={(c) => (
              <form onClick={(e) => e.stopPropagation} onSubmit={onSubmit}>
                {c}
              </form>
            )}>
            <>
              <div className={`flex justify-between items-center ${title ? 'p-2' : ''}`}>
                <p className="text-xl">{title}</p>
                {headerAction}
                {displayCloseButton && (
                  <IconButton onClick={() => onClose?.()} size="small">
                    <FontAwesomeIcon icon={faTimes} />
                  </IconButton>
                )}
              </div>
              {children(onClose, onSubmit)}
            </>
          </ConditionalWrapper>
        </div>
      </Dialog>
    </>
  );
}
